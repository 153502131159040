<template>
    <div>   
        <CForm ref="form" class="needs-validation"   >
            <CRow>
                <CCol>
                    <CSelect
                        horizontal
                        label="Üretici"
                        placeholder="Üretici"
                        :disabled="disabledAreas"
                        :value.sync="data.productionCompany"
                        :options="productionCompanies"
                        @change="getOrderList"
                    />
                </CCol>
                <CCol>
                    <CSelect
                        horizontal
                        label="Sipariş No"
                        placeholder="Sipariş No"
                        :disabled="disabledAreas"
                        :value.sync="data.orderId"
                        :options="orders"
                        @change="getModelList"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <CInput
                        label="Fatura No: "
                        horizontal
                        placeholder="0"
                        type="text"
                        name="invoiceNo"
                        v-model="data.invoiceNo"
                    />
                </CCol>
                <CCol>
                    <CInput
                        label="Fatura Tarihi: "
                        type="date"
                        horizontal
                        name="invoiceDate"
                        v-model="data.invoiceDate"
                    />
                </CCol>
            </CRow>
           <CRow>
            <CCol>
                <CInput
                    label="İrsaliye No: "
                    horizontal
                    placeholder="0"
                    type="text"
                    name="dispatchNo"
                    v-model="data.dispatchNo"
                />
                </CCol>
                <CCol>
                    <CInput
                        label="İrsaliye Tarihi: "
                        type="date"
                        horizontal
                        name="dispatchDate"
                        v-model="data.dispatchDate"
                    />
                </CCol>
           </CRow>
           <CRow>
                <CCol>
                    <CInput
                        label="Tutar: "
                        horizontal
                        placeholder="100"
                        type="text"
                        name="totalAmount"
                        v-model="data.totalAmount"
                    />
                </CCol>
                <CCol>
                    <CInput
                        label="KDV: "
                        horizontal
                        placeholder="100"
                        type="text"
                        name="vatAmount"
                        v-model="data.vatAmount"
                    />
                </CCol>
           </CRow>
              
              <input name="file" type="file" @change="handleFileUpload($event)" required/>
        </CForm>
    </div>
</template>

<script>
   import 'vue-multiselect/dist/vue-multiselect.min.css'
   export default{
       name: "PackingForm",
       props:{
            additionalEvent: Function,
            params: Object,
            actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create'){
                   this.data = {};
                   this.disabledAreas = false;
               }else{
                this.disabledAreas = true;
               }
           },
       },
       computed:{
        packingItems: function() {
            return this.$store.getters.productionPackingItems
        },
        productionCompanies: function() {
            let data = ['Üretici Seç'];
            this.$store.getters.productionCompanies.map(pc => data.push({label: pc.name, value: pc.id}))
            return data
        },
        orders: function() {
            let data = ['Sipariş No Seç'];
            this.$store.getters.productionCompanyOrders.map(i => data.push({label: i.orderNo, value: i.id}))
            return data
        },
        models: function() {
            let data = ['Model Seç'];
            this.$store.getters.productionModels.map(m => data.push({label: m.productName, value: m.productId}))
            return data
        }
        
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               disabledAreas: this.actionType !== 'create',
               packingListFields: [
                    {key: 'packingNumber', label: "Koli No"},
                    {key: 'shipmentLocation', label: "Sevk Yeri"},
                    {key: 'XS', label: "XS"},
                    {key: 'S', label: "S"},
                    {key: 'M', label: "M"},
                    {key: 'L', label: "L"},
                    {key: 'XL', label: "XL"},
                    {key: 'totalAmount', label: "Toplam Adet"},
                    {key: 'packingCode', label: "Koli Kodu"},
                    {key: 'edit', label: "Düzenle"},
                    {key: 'delete', label: "Sil"},
                ],
                genderList: ["ERKEK", "KADIN", "UNISEX"],
               modalTitle: '',
               module: 'notification',
               form: 'newsletterForm',
               modalProps: {},
               modalDesc: '',
               openModal: false,
           }
       },
       methods: {
           getOrderList: async function(){
                await this.$store.dispatch('productionCompanyOrder_list', {productionCompanyId: this.data.productionCompany})
           },
           getModelList: async function(){
                if(this.data.orderId && this.data.gender){
                    let params = {
                        productionOrderId: this.data.orderId,
                        gender: this.data.gender
                    }
                    await this.$store.dispatch('productionModel_list', params)
                }
           },
           handleFileUpload(item) {
                this.data.file = item.target.files[0];
            },
       }
   }
</script>